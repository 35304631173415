body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiInputBase-inputMultiline {
  height: 280px !important;
  resize: none !important;
  padding: 0;
}


/* loader */

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #f09c01;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile-img {
  width: 50px;
  height: 50px;
  font-size: 30px;.main_div {
  padding: 10px;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.react-datepicker__month-container {
  box-shadow: 5px 5px 20px #0002;
}

.ant-table-column-sorters::after {
  z-index: 0;
}
  font-weight: 400;
  padding-left: 16px;
  padding-top: 2px;
  color: white;
}

.btn-action {
  background-color: #f09c01;
}

