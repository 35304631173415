@import "~bootstrap/scss/bootstrap";

button{
    outline:0 !important;
}
.main_div {
	padding: 10px;
}
.react-datepicker-popper {
	z-index: 5 !important;
}
.react-datepicker__month-container {
	box-shadow: 5px 5px 20px #0002;
}
.ant-table-column-sorters {
	&::after {
		z-index: 0;
	}
}
