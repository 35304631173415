$primary-color :#f09C01;

@mixin sidebarTab {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

@mixin sidebarBtnText {
    line-height: 30px;
    font-size: 17px;
    margin: 0;
}

.home-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.sidebarBtn {
    @include sidebarTab();
    margin: 10px 3px 0;
    background-color: transparent;

    // justify-content:space-between;
    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
}

.sidebarNestedBtn {
    @include sidebarTab();
    background-color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }
}

.sidebarNestedBtnSelected {
    @include sidebarTab();
    background-color: $primary-color;
    box-shadow: 0 12px 20px -10px rgba(232, 41, 83, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 145, 224, 0.2);
    margin: 4px;
    padding: 8px;
    border-radius: 4px;
}

.sidebarBtnSelected {
    @include sidebarTab();
    background-color: $primary-color;
    box-shadow: 0 12px 20px -10px rgba(232, 41, 83, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 145, 224, 0.2);
    margin: 10px 3px 0;
    padding: 8px;
    border-radius: 4px;
}


.sidebarBtnIcon {
    width: 30px;
    margin-right: 12.5px;
    margin-left: 6px;
}

.breadIcon {
    margin-right: 3px;
    margin-left: 3px;
}

.sidebarBtnTextSelected {
    @include sidebarBtnText();
    color: #ffffff;
    // font-weight: 300;
}

.sidebarBtnText {
    @include sidebarBtnText();
    color: #3c4858;
}

.sidebarBackground {
    background-image: url('../../assets/images/sidebarBg.jpg');
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}

.sidebarBackground:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background: #FFFFFF;
    opacity: .93;
}


.MuiListItem-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.MuiListItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* box shadow for sidebar */
.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
}

// breadcrumb
// .ant-breadcrumb{
//     padding: 10px;
//     background-color: #ffffff;
//     border-radius: 4px;
// }

// .ant-breadcrumb-link {
//     color: #222222;  
//     margin: 0 2px;
//     font-size: 18px;

//     &:hover  {
//         color: #222222;    
//         text-decoration: none;
//         }
// }

.ant-input {
    width: 30%;
    border-radius: 50px;
    padding: 10px;
}

.card-body {
    margin-top: 1.25rem;
}

.justifyTerminalTab {
    justify-content: space-between;
}

.Toastify__progress-bar--success {
    background: #f09C01 !important;
}

.Toastify__toast-icon svg {
    fill: #f09C01;
}

.Toastify__progress-bar--error {
    background: #f09C01 !important;

}